<template >
  <b-container fluid>
    <loading :active.sync="isWorkingClient" :is-full-page="true"></loading>
    <b-row align-h="center">
    <h4>Catálogo de Trámites</h4><br><br><br><br><br>
    </b-row>
    <b-row>
      <b-col class="col-tramite-client" xl="3" lg="4" md="6" sm="6" cols="12" v-for="categoria in categorias" :key="categoria.idCategoria">
        <transition name="slide-vertical">
          <b-card class="c-shadow-cat" v-if="isShowCardsClient">
            <b-row align="center" justify="center">
                <div class="img-supposition-rounded">
                  <div :style="iconBall" >
                    <img width="45px;" height="45px;" style="margin-top:25px;" :src="'../imgs/' + categoria.icon" />
                  </div>
                </div><br><br>
            </b-row>
            <b-row align="center">
              <b-col cols="12">
                  <h5>{{categoria.nombreCategoria}}</h5>
              </b-col>
            </b-row>
            <b-dropdown class="dropdown" size="sm" variant="secondary" :text="'Trámites en línea: ' + categoria.numDigitales">
              <div v-for="tramite in categoria.tramites" :key="tramite.idServiceInfo">
                <b-dropdown-item v-if="tramite.enLinea" @click="showRevisionTramite(tramite.idServiceInfo)"><span class="item-list-tramites">{{tramite.nombreServiceInfo}} ({{tramite.status}})</span></b-dropdown-item>
              </div>
            </b-dropdown><br>
            <b-dropdown class="dropdown" size="sm" variant="secondary" :text="'Trámites informativos: ' + categoria.numSimples">
              <div v-for="tramite in categoria.tramites" :key="tramite.idServiceInfo">
                <b-dropdown-item v-if="!tramite.enLinea" @click="showRevisionTramite(tramite.idServiceInfo)"><span class="item-list-tramites">{{tramite.nombreServiceInfo}}  ({{tramite.status}})</span></b-dropdown-item>
              </div>
            </b-dropdown>
          </b-card>
        </transition><br><br>
      </b-col>
    </b-row>
    <b-row align-h="center">
    <h4>Otras Operaciones</h4><br><br><br><br>
    </b-row>
    <b-row>
      <b-col class="col-tramite-client" xl="3" lg="4" md="6" sm="6" cols="12">
        <transition name="slide-vertical">
          <b-card class="c-shadow-sm" v-if="isShowCardsClient">
            <b-row align="center" justify="center">
                <div class="img-supposition-rounded">
                  <div :style="iconBall">
                    <img width="45px;" height="45px;" style="margin-top:25px;" :src="'../imgs/documentos.png'" />
                  </div>
                </div><br><br>
            </b-row>
            <b-row align="center">
              <b-col cols="12">
                <h5>
                  AGREGAR NUEVO TRÁMITE
                </h5>
              </b-col>
            </b-row>
            <!---- ES PARA IMPORTAR MEDIANTE ARCHIVO b-button block variant="warning" size="sm" @click="showPanelImportarTramites">Importar Trámites</b-button> --->
            <b-button block size="sm" @click="showRevisionTramite(null)" :style="{ background: gradientColor}">Agregar nuevo</b-button>
          </b-card>
        </transition>
      </b-col>
      <b-col class="col-tramite-client" xl="3" lg="4" md="6" sm="6" cols="12">
        <transition name="slide-vertical">
          <b-card class="c-shadow-sm" v-if="isShowCardsClient">
            <b-row align="center" justify="center">
                <div class="img-supposition-rounded">
                  <div :style="iconBall">
                    <img width="45px;" height="45px;" style="margin-top:25px;" :src="'../imgs/documento_personal.png'" />
                  </div>
                </div><br><br>
            </b-row>
            <b-row align="center">
              <b-col cols="12">
                <h5>
                  CONFIGURAR ADMINISTRADORES
                </h5>
              </b-col>
            </b-row>
            <b-button block size="sm" @click="showPanelAdministradores" :style="{ background: gradientColor }">Configurar</b-button>
          </b-card>
        </transition>
      </b-col>
      <b-col class="col-tramite-client" xl="3" lg="4" md="6" sm="6" cols="12">
        <transition name="slide-vertical">
          <b-card class="c-shadow-sm" v-if="isShowCardsClient">
            <b-row align="center" justify="center">
                <div class="img-supposition-rounded">
                  <div :style="iconBall">
                    <img width="45px;" height="45px;" style="margin-top:25px;" :src="'../imgs/documentos.png'" />
                  </div>
                </div><br><br>
            </b-row>
            <b-row align="center">
              <b-col cols="12">
                <h5>
                  CATEGORIAS
                </h5>
              </b-col>
            </b-row>
            <b-button block size="sm" @click="showPanelCategorias" :style="{ background: gradientColor }">Configurar</b-button>
          </b-card>
        </transition>
      </b-col>
    </b-row>

    <!--------- modals ---------->
    <b-modal title="Importar Trámites" v-model="isShowModalImportarTramites" size="md" hide-footer>
      Selecciona el archivo que deseas importar:
      <b-form-file @change="importTramites" browse-text="Buscar" placeholder="Selecciona un archivo . . ." ></b-form-file>
    </b-modal>
  </b-container>
</template>

<script>
  import { mapState} from 'vuex';
  import { tramitesClient, infoTramiteClient, buscaAdministradoresClient, importatramitesClient, categoriasClient } from '@/axios-tools';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    computed: {
      ...mapState({
      token: state => state.client.token,
      categorias: state => state.client.categorias,
      isWorkingClient: state => state.client.isWorkingClient,
      isShowCardsClient: state => state.client.isShowCardsClient,
      iconsColor: state => state.user.iconsColor,
      principalColor : state => state.user.principalColor,
      gradientColor : state => state.user.gradientColor,

    }),
    iconBall: function(){
      return " display: block; width: 100%; height: 100%; align-items: center; " +
     
      "background:" +this.gradientColor+";"+
      "border-radius: 50%; " +
      "box-shadow: inset -25px -25px 40px rgba(10,10,30,.4); " +
      "background-image: -webkit-linear-gradient(-45deg, rgba(255,255,220,.2) 0%, transparent 100%); " +
      "background-image: -moz-linear-gradient(-45deg, rgba(255,255,220,.2) 0%, transparent 100%); " +
      "background-image: -o-linear-gradient(-45deg, rgba(255,255,220,.2) 0%, transparent 100%); " +
      "background-image: -ms-linear-gradient(-45deg, rgba(255,255,220,.2) 0%, transparent 100%); " +
      "position:absolute; left: 0px; top:0px; text-align:center; ";
    }
    },
    components: {
      Loading
    },
    data() {
      return {
        cliente: location.pathname.split("/", 2).toString().replace(",", ""),
        isShowModalImportarTramites:false
      }
    },
    created: function () {      
      tramitesClient(this.token, this.$store);
    },
    methods: {
      importTramites(evt){
        var reader = new FileReader();
        var thisObject = this;
        reader.onload = function(event) {
          var stringXml = event.target.result;
          importatramitesClient(thisObject.token, stringXml, thisObject.$store, thisObject.$router);
        };
        reader.readAsText(evt.target.files[0]);
      },
      showPanelImportarTramites(){
        this.isShowModalImportarTramites=true;
      },
      showPanelAdministradores(){
        buscaAdministradoresClient(this.$store, this.$router, this.$route.params.municipio, this.token);
        this.$store.commit("updateIsShowCardsClient", false); 
      },
      showPanelCategorias(){
        categoriasClient(this.token, this.$store, this.$router, this.$route.params.municipio);
      },
      showRevisionTramite(idServiceInfo){
        //si idServiceInfo is null significa que queremos cargar el modelo de un service info en limpio
        infoTramiteClient(this.cliente, this.token, idServiceInfo, this.$store, this.$router);
        this.$store.commit("updateIsShowCardsClient", false);
      }
    },
    validations: {
        
    }
  }
</script>
<style>
.item-list-tramites{
  color:#2d1a65; 
  font-weight: bold;
}
.col-tramite-client{
  margin-bottom:30px;
}
.button-buscar{
  margin-top:30px;
}
.dropdown{
  min-width:100%;
  margin-top:5px;
}
.c-shadow-cat{
  background-color:#FFFFFF;
  box-shadow: 7px 7px 15px 2px #B5B5B5;
  margin-bottom:10px;
  min-height:220px;
}
/* Estilos usados para la transición horizontal (Titulos) */
.slide-fade-enter-active {
  transition: all 0.9s ease;
}
.slide-fade-leave-active {
  transition: all 0.9s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to{
  transform: translateY(100px);
  opacity: 0;
}

/* Estilos usados para la transición vertical (v-cards e imagenes) */
.slide-vertical-enter-active {
  transition: all 0.9s ease;
}
.slide-vertical-leave-active {
  transition: all 0.9s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-vertical-enter, .slide-vertical-leave-to{
  transform: translateX(-70px);
  opacity: 0;
}
.img-supposition-rounded{
  background: linear-gradient(90deg, rgba(28,81,141,1) 0%, rgba(0,57,120,1) 100%);
  box-shadow: 4px 4px 15px 1px #7C7C7C;
  border-radius: 50%;
  font-size:20px;
  padding:20px;
  width: 98px;
  height: 98px;
  position:absolute;
  top:-49px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}
</style>